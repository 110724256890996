import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomToastComponent } from '../../component/custom-toast/custom-toast.component';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  constructor(private dialog: MatDialog) { }
  dialogRef: any;
  dialogRefCustom: any;
  dialogRefParameter: any;
  showError(message: string): void {
    this.dialogRef = this.dialog.open(CustomToastComponent, {
      data: {
        "message": message
      }
    });
    document.body.classList.add('disable-scroll');
    setTimeout(() => {
      this.dialogRef.close();
      document.body.classList.remove('disable-scroll');
    }, 10000);
  }

  closeDialog() {
    if(this.dialogRef)
    {
    this.dialogRef.close();
    document.body.classList.remove('disable-scroll');
    }
  }
  showDialog(dialogConfig: any, component: any): MatDialogRef<any> {
    document.body.classList.add('disable-scroll');
    dialogConfig.disableClose = true; // Disable closing on backdrop click or ESC
    dialogConfig.hasBackdrop = true; // Display backdrop
    this.dialogRefCustom = this.dialog.open(component, dialogConfig);

    this.dialogRefCustom.afterClosed().subscribe(()=>{
      document.body.classList.remove('disable-scroll');
    })
    return this.dialogRefCustom;
  }
  closeCustomDialog() {
    if(this.dialogRefCustom)
    {
    this.dialogRefCustom.close();
    }
  }

  closeWithParameters(result: any):any {
    this.dialogRefCustom.close(result['selectedDate']);
  }

  openDialogWithParameters(dialogConfig: any, component: any, date: string): MatDialogRef<any> {
    document.body.classList.add('disable-scroll');
    dialogConfig.disableClose = true; // Disable closing on backdrop click or ESC
    dialogConfig.hasBackdrop = true;
    dialogConfig.data = {
      param1: date // Pass your parameters here
    };
    this.dialogRefCustom = this.dialog.open(component, dialogConfig);

    this.dialogRefCustom.afterClosed().subscribe(()=>{
      document.body.classList.remove('disable-scroll');
    })
    return this.dialogRefCustom;
  }
}

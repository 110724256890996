export class AppRoute {
    public static AUTH = 'auth';
    public static LOGIN_PAGE = 'login';
    public static SPLASHSCREEN_PAGE = "splash";
    public static FORGOT_PAGE = 'forgot';
    public static ENROLL_PAGE = 'enroll';
    public static OTP_PAGE = 'otp';
    public static SETPASSWORD_PAGE = 'set-password';
    public static WEBPAGEURL_PAGE = 'payliance-web/';
    public static WEBPAGEURLRECEVIER_PAGE = 'payliance-web/:Id';

    //Home Routing
    public static HOME = 'home';
    public static DASHBOARD_PAGE = 'dashboard';
    public static TRANSACTIONS = 'transactions';
    public static ACCOUNTS = 'accounts';
    public static MORE = 'more';
    public static REQUEST_DATE_CHANGE_PAGE = 'request-date-change';
    public static Calendar_PAGE = 'request-date-change-calendar';
    public static Shared = 'shared';

    //Common-Routing
    public static PrivayPolicy = 'app-privacy-policy';
    public static TermCondition = 'app-terms-conditions';
    public static TermsService = 'app-terms-of-service';
    public static FAQ = 'app-faqs';
    public static PaymentAuth = 'app-payment-authorization';
    public static ElectronicConsent = 'app-electronic-communication-consent';
    public static Profile = 'app-profile'
}

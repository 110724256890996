import { state, style, trigger } from '@angular/animations';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupService } from '../../Service/popup-service/popup.service';

@Component({
  selector: 'app-custom-toast',
  templateUrl: './custom-toast.component.html',
  styleUrls: ['./custom-toast.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: 0 })),
    ]),
  ],
})
export class CustomToastComponent {
constructor(public popupService:PopupService, @Inject(MAT_DIALOG_DATA) public data: any){
}
popUpBackClick(){
  this.popupService.closeDialog();
}
}

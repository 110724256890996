export class ApiUrl {  
  public static ROOT_URL = document.getElementsByTagName('base')[0].href;

  //auth URL
  public static MODULE = "Auth/";
  public static LOGIN_URL = "Login";
  public static FORGOT_PASSWORD_URL = "ForgotPassword";
  public static CHECK_OTP_URL = "CheckOTP";
  public static RESET_PASSWORD = "SetPassword";
  public static CHANGE_PASSWORD = "ChangePassword";
  public static REGISTERUSER = "RegisterUser";
  public static Enroll = "Enroll";
  public static CheckUser = "CheckUser";
  public static LOGOUT_URL = "Logout";
  //loan URL
  public static LOAN_MODULE = "loan/";
  public static GET_LOAN_LIST_URL = "GetLoanList";
  public static GET_ACCOUNT_DATA = "GetAccountData";
  public static RETRIEVE_LOAN_DATES_URL = "RetrieveLoanDates";
  public static DELETE_ACCOUNT_URL = "DeleteAccounts";
  public static ADD_ACCOUNT_URL = "AddAccount";
  public static ADD_CARD_URL = "AddCard";
  public static SUBMIT_NEW_REQUEST_LOAN_DATE_URL = "SubmitNewRequestLoanDate";
  public static RETRIVE_PAY_NOW_URL = "RetrivePayNow";
  public static SUMIT_PAYMENT_URL = "SubmitPayNow";
  public static GET_TRANSACTION_LIST_URL = "GetTransactionList";
  public static CANCEL_TRANSACTION_URL = "CancelTransaction";

  //CustomerAPI
  public static CUSTOMER = "customer/"
  public static RETRIVE_PROFILE = "RetriveProfile"
  public static SAVE_PROFILE = "SaveProfile"

  //Common URL
  public static COMMON_MODULE = "Common/";
  public static Privacy_MODULE = "Privacy/";
}

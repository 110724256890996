import { Injectable } from '@angular/core';
import { LoanModel } from '../../model/get-loan-list/get-loan-list-model';

@Injectable({
  providedIn: 'root'
})
export class UserPerferenceService {
 phoneNumber: string;
 oneTimeId: string;
 enrollmentId: string;
 selectedLoan: LoanModel;
  //#region phone Number Data
  setPhoneNumberData(data: string) {
    this.phoneNumber = data;
  }
  getPhoneNumberData() {
    return this.phoneNumber;
  }
  //#endregion
  
  //#region OneTimeId Data
  setoneTimeIdData(data: string) {
    this.oneTimeId = data;
  }
  getoneTimeIdData() {
    return this.oneTimeId;
  }
  //#endregion
  //#region Enroll ID Data
  getEnrollmentID() {
    return this.enrollmentId;
  }

  setEnrollmentID(data: string) {
    this.enrollmentId = data;
  }
  //#endregion
  //#region Last Selected Loan Data
  getSelectedLoan() {
    return this.selectedLoan;
  }

  setSelectedLoan(data: LoanModel) {
    this.selectedLoan = data;
  }
  //#endregion
}

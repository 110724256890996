export class Constant {
    public static OtpSentMsg="Identification code sent successfully";
    public static INVALID_OTP="Invalid identification code";
    public static SOMETHING_WENT_WRONG_ERROR = "Something went wrong. Please try again later";
    public static InternetError = "Please check your Internet connection and try again later";
}

export enum WebUrl{
    Privacy = "PrivacyPolicy",
    TermsCondition = "Terms&Conditions"
}

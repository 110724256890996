import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ApiUrl } from '../../core/services/APIURL/api-url';
import { LoginRequestModel, LoginResponseModel } from '../Model/login/login-response-model';
import { ForgotPasswordRequestModel, ForgotPasswordResponseModel } from '../Model/forgot-password/forgot-password-response-model';
import { OtpRequestModel, OtpResponseModel } from '../Model/OTP/otp-response-model';
import { ResetPasswordRequestModal } from '../Model/reset-password/reset-password';
import { EnrollRequestModel, EnrollResponseModel } from '../Model/Enroll/enroll-model';
import { SetupPasswordRequestModal } from '../Model/setup-password/setup-password-model';
import { CheckUserRequestModel, CheckUserResponseModel } from '../Model/Checkuser/check-user-model';
import { BaseResponseModel } from '../../core/model/base-response-model';
import { LogoutUserRequestModel } from '../Model/logout';
import { ChangePasswordRequestModel, ChangePasswordResponseModal } from '../Model/change-password/change-password';
import { UserPerferenceService } from '../../shared/Service/user-preference/user-perference.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isShowSubject = new BehaviorSubject<boolean>(false);
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }),
  };
  startTime: number;
  timerObj: any;
  mob: string;
    constructor(public http: HttpClient,public userPreference: UserPerferenceService) {
      const otpTimer = localStorage.getItem('startTime')
      if(otpTimer != null){
        this.startTimer();
      }
     }
  LoginUser(data: LoginRequestModel): Observable<LoginResponseModel> {
        return this.http
          .post<LoginResponseModel>(
            `${ApiUrl.ROOT_URL}` + `${ApiUrl.MODULE}` + `${ApiUrl.LOGIN_URL}`,
                JSON.stringify(data),
                this.httpOptions
            )
            .pipe(retry(1), catchError(this.errorHandl));
  }

  ForgotPassword(data: ForgotPasswordRequestModel): Observable<ForgotPasswordResponseModel> {
    return this.http
      .post<ForgotPasswordResponseModel>(
        `${ApiUrl.ROOT_URL}` + `${ApiUrl.MODULE}` + `${ApiUrl.FORGOT_PASSWORD_URL}`,
            JSON.stringify(data),
            this.httpOptions
        )
        .pipe(retry(1), catchError(this.errorHandl));
}

CheckOTP(data: OtpRequestModel): Observable<OtpResponseModel> {
  return this.http
    .post<OtpResponseModel>(
      `${ApiUrl.ROOT_URL}` + `${ApiUrl.MODULE}` + `${ApiUrl.CHECK_OTP_URL}`,
          JSON.stringify(data),
          this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandl));
}

ResetPassword(data: ResetPasswordRequestModal): Observable<any> {
  return this.http
    .post<any>(
      `${ApiUrl.ROOT_URL}` + `${ApiUrl.MODULE}` + `${ApiUrl.RESET_PASSWORD}`,
          JSON.stringify(data),
          this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandl));
}

RegisterUser(data: SetupPasswordRequestModal): Observable<any> {
  return this.http
    .post<any>(
      `${ApiUrl.ROOT_URL}` + `${ApiUrl.MODULE}` + `${ApiUrl.REGISTERUSER}`,
          JSON.stringify(data),
          this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandl));
}

CheckUser(data: CheckUserRequestModel): Observable<CheckUserResponseModel> {
  return this.http
    .post<CheckUserResponseModel>(
      `${ApiUrl.ROOT_URL}` + `${ApiUrl.MODULE}` + `${ApiUrl.CheckUser}`,
          JSON.stringify(data),
          this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandl));
}

Enroll(data: EnrollRequestModel): Observable<EnrollResponseModel> {
  return this.http
    .post<EnrollResponseModel>(
      `${ApiUrl.ROOT_URL}` + `${ApiUrl.MODULE}` + `${ApiUrl.Enroll}`,
          JSON.stringify(data),
          this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandl));
}

LogoutUser(data:LogoutUserRequestModel): Observable<BaseResponseModel> {
  return this.http
    .post<BaseResponseModel>(
      `${ApiUrl.ROOT_URL}` + `${ApiUrl.MODULE}` + `${ApiUrl.LOGOUT_URL}`,
          JSON.stringify(data),
          this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandl));
}

ChangePassword(data: ChangePasswordRequestModel): Observable<ChangePasswordResponseModal> {
  return this.http
    .post<ChangePasswordResponseModal>(
      `${ApiUrl.ROOT_URL}` + `${ApiUrl.MODULE}` + `${ApiUrl.CHANGE_PASSWORD}`,
          JSON.stringify(data),
          this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandl));
}

  errorHandl(error: any) {
    try{
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message ? error.error.message : error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => {
      return errorMessage;
    });
  }
  catch(e){}}

  startTimer() {
    this.startTime = parseInt(localStorage.getItem('startTime')) || 1;
    this.timerObj = setInterval(() => this.updateTimer(), 1000);
  }

  updateTimer() {
    const currentTime = Math.floor((this.startTime++));
    localStorage.setItem('startTime',JSON.stringify(currentTime))
    if(this.isTimerCompleted())
    {
      this.resetTimer();
    }  
  }

  private isTimerCompleted(): boolean {
    return parseInt(localStorage.getItem('startTime')) >= 3600;
  }

  resetTimer(){
    this.mob = this.userPreference.getPhoneNumberData() ? this.userPreference.getPhoneNumberData() : localStorage.getItem("UserMobile")
    localStorage.removeItem("startTime");
    localStorage.removeItem(this.mob);
    localStorage.removeItem("UserMobile");
    clearInterval(this.timerObj);
  }
}

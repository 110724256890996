import { Component, HostListener } from '@angular/core';
import { AuthService } from './modules/auth/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';
  isOffline = !navigator.onLine;
  constructor(public authService: AuthService){}
  isShow = this.authService.isShowSubject.asObservable();

  @HostListener('window:online', ['$event'])
  onOnline(event: Event) {
    this.isOffline = false;
    // Reload the page when online
    window.location.reload();
  }

  @HostListener('window:offline', ['$event'])
  onOffline(event: Event) {
    this.isOffline = true;
  }
}

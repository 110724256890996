import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRoute } from './modules/core/helper/app-route';
import { HttpRequestInterceptor } from './modules/shared/Service/Interceptor/http-request.interceptor';
import { LoaderComponent } from './modules/shared/component/loader/loader.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomI18n, NgbDateCustomParserFormatter } from './modules/shared/Service/NgbDateCustomParserFormatter/ngb-date-custom-parser-formatter.service';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { DatePipe } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const routes: Routes=[
  {
    path: `${AppRoute.AUTH}`,
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: `${AppRoute.HOME}`,
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  { path: '', redirectTo: `${AppRoute.AUTH}`, pathMatch: 'full' },
  { path: '**', redirectTo: `${AppRoute.AUTH}` } // Redirect invalid routes to AUTH module or a 404 page

]

@NgModule({
  declarations: [AppComponent, LoaderComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    MatDialogModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    NgbModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    { provide: NgbDatepickerI18n, useClass: NgbDateCustomI18n },
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  format(date: NgbDateStruct): string {
    if (date) {
      return `${String.fromCharCode(65 + this.getDayOfWeek(date)).toUpperCase()}${date.month}-${date.day}-${date.year}`;
    }
    return '';
  }

  parse(value: string): NgbDateStruct | null {
    if (!value) {
      return null;
    }

    const parts = value.split('-');
    if (parts.length === 3) {
      const day = +parts[1];
      const month = +parts[0];
      const year = +parts[2];
      return { year, month, day };
    }

    return null;
  }

  private getDayOfWeek(date: NgbDateStruct): number {
    // Create a JavaScript Date object
    const jsDate = new Date(date.year, date.month - 1, date.day);

    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    return jsDate.getDay();
  }
}
@Injectable()
export class NgbDateCustomI18n extends NgbDatepickerI18n {
  getDayAriaLabel(date: NgbDateStruct): string {
    return date.day.toString();
  }
  getWeekdayShortName(weekday: number): string {
    const weekdays: string[] = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT','SUN'];
    return weekdays[weekday - 1];
  }

  getWeekdayLabel(weekday: number): string {
    return this.getWeekdayShortName(weekday);
  }

  getMonthShortName(month: number): string {
    const monthAbbreviations: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Ensure month is within the valid range (1 to 12)
    if (month >= 1 && month <= 12) {
      return monthAbbreviations[month - 1];
    } else {
      return ''; // Return an empty string for invalid months
    }
  }

  getMonthFullName(month: number): string {
    // You can provide custom full month names here if needed.
    // Example: ['January', 'February', 'March', ...]
    // Replace the array elements with your custom full month names.
    return `${month}`;
  }
}
import { Injectable, NgZone } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { LoanService } from '../api-service/loanService/loan.service';
import { PopupService } from '../popup-service/popup.service';
import { Constant } from '../../../core/helper/constant-file/constant';
import { catchError, tap, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppRoute } from 'src/app/modules/core/helper/app-route';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  private activeRequests = 0;

  constructor(
    public ngZone: NgZone,
    public authService: AuthService,
    public loanService: LoanService,
    public dialog: PopupService,
    private router: Router,
    public popupService: PopupService,
    private Matdialog: MatDialog
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!navigator.onLine) {
      this.dialog.showError(Constant.InternetError);
      return throwError(Constant.InternetError);
    }

    let token = JSON.parse(localStorage.getItem("Session"));
    if (token != null) {
      request = request.clone({
        setHeaders: {
          Authorization: `${token}`
        }
      });
    }

    this.authService.isShowSubject.next(true);
    this.activeRequests++;

    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          if (event.body.invalidSession) {
            localStorage.removeItem("Session");
            this.popupService.closeCustomDialog();
            this.popupService.closeDialog();
            this.Matdialog.closeAll();
            this.popupService.showError("Session Expired");
            this.ngZone.run(() => this.router.navigate([`${AppRoute.AUTH}/${AppRoute.LOGIN_PAGE}`]));
          }
        }
      }),
      catchError((error) => {
        return throwError(error);
      }),
      finalize(() => {
        this.activeRequests--;
        if (this.activeRequests === 0) {
          this.authService.isShowSubject.next(false);
        }
      })
    );
  }
}

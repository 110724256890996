import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ApiUrl } from 'src/app/modules/core/services/APIURL/api-url';
import { GetLoanListResponseModel } from '../../../model/get-loan-list/get-loan-list-model';
import { GetAccountsDataRequestModel, GetAccountsDataResponseModel } from '../../../model/get-accounts-data/get-accounts-data';
import { DeleteAccountRequestModel, DeleteAccountRespModel } from '../../../model/delete-account/delete-account';
import { AddAccountRequestModel, AddAccountResponseModel } from '../../../model/add-account/add-account-model';
import { AddCardRequestModel, AddCardResponseModel } from '../../../model/add-card/add-card-model';
import { GetTransactionListRequestModel, GetTransactionListResponseModel } from '../../../model/get-transaction-list/get-transaction-list-model';
import { BaseResponseModel } from 'src/app/modules/core/model/base-response-model';
import { CancelTransactionRequestModel } from '../../../model/cancel-transaction/cancel-transaction-request-model';
import { SaveProfileRequestModal, SaveProfileResponseModal } from '../../../model/save-profile/save-profile';
import { RetriveProfileRequestModal, RetriveProfileResponseModal } from '../../../model/retrive-profile/retrive-profile';

@Injectable({
  providedIn: 'root'
})
export class LoanService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }),
  };
  constructor(public http: HttpClient) { }
  GetListOfLoan(): Observable<GetLoanListResponseModel> {
    const data = this.http
    .get<GetLoanListResponseModel>(
      `${ApiUrl.ROOT_URL}` + `${ApiUrl.LOAN_MODULE}` + `${ApiUrl.GET_LOAN_LIST_URL}`
      )
      .pipe(retry(1), catchError(this.errorHandl));
    return data;
}

  GetAccountData(data: GetAccountsDataRequestModel): Observable<GetAccountsDataResponseModel> {
  return this.http
    .post<GetAccountsDataResponseModel>(
      `${ApiUrl.ROOT_URL}` + `${ApiUrl.LOAN_MODULE}` + `${ApiUrl.GET_ACCOUNT_DATA}`,
          JSON.stringify(data),
          this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandl));
}

  DeleteAccounts(data: DeleteAccountRequestModel): Observable<DeleteAccountRespModel> {
  return this.http
    .post<DeleteAccountRespModel>(
      `${ApiUrl.ROOT_URL}` + `${ApiUrl.LOAN_MODULE}` + `${ApiUrl.DELETE_ACCOUNT_URL}`,
          JSON.stringify(data),
          this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandl));
}

AddAccount(data: AddAccountRequestModel): Observable<AddAccountResponseModel> {
  return this.http
    .post<AddAccountResponseModel>(
      `${ApiUrl.ROOT_URL}` + `${ApiUrl.LOAN_MODULE}` + `${ApiUrl.ADD_ACCOUNT_URL}`,
          JSON.stringify(data),
          this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandl));
}

AddCard(data: AddCardRequestModel): Observable<AddCardResponseModel> {
  return this.http
    .post<AddCardResponseModel>(
      `${ApiUrl.ROOT_URL}` + `${ApiUrl.LOAN_MODULE}` + `${ApiUrl.ADD_CARD_URL}`,
          JSON.stringify(data),
          this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandl));
}
GetTransactionList(data: GetTransactionListRequestModel): Observable<GetTransactionListResponseModel> {
  return this.http
    .post<GetTransactionListResponseModel>(
      `${ApiUrl.ROOT_URL}` + `${ApiUrl.LOAN_MODULE}` + `${ApiUrl.GET_TRANSACTION_LIST_URL}`,
          JSON.stringify(data),
          this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandl));
}
CancelTransactionList(data: CancelTransactionRequestModel): Observable<BaseResponseModel> {
  return this.http
    .post<GetTransactionListResponseModel>(
      `${ApiUrl.ROOT_URL}` + `${ApiUrl.LOAN_MODULE}` + `${ApiUrl.CANCEL_TRANSACTION_URL}`,
          JSON.stringify(data),
          this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandl));
}

RetriveProfile(data: RetriveProfileRequestModal): Observable<RetriveProfileResponseModal> {
  return this.http
    .post<any>(
      `${ApiUrl.ROOT_URL}` + `${ApiUrl.CUSTOMER}` + `${ApiUrl.RETRIVE_PROFILE}`,
          JSON.stringify(data),
          this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandl));
}

SaveProfile(data: SaveProfileRequestModal): Observable<SaveProfileResponseModal> {
  return this.http
    .post<SaveProfileResponseModal>(
      `${ApiUrl.ROOT_URL}` + `${ApiUrl.CUSTOMER}` + `${ApiUrl.SAVE_PROFILE}`,
          JSON.stringify(data),
          this.httpOptions
      )
      .pipe(retry(1), catchError(this.errorHandl));
}

errorHandl(error: any) {
  let errorMessage = '';
  if (error.error instanceof ErrorEvent) {
    // Get client-side error
    errorMessage = error.error.message;
  } else {
    // Get server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  return throwError(() => {
    return errorMessage;
  });
}
}
